export function getSchemaColor(options, status, d=null) {
    let name = status;
    if (status === undefined) {
      name = 'None';
    }

    const statusObject = options.find(e => e.name === name);

    if (statusObject !== undefined && 'color' in statusObject) {
      return statusObject.color;
    } else {
      return false;
    }
    
}