import React from 'react';

export default () => {
    return (
        <div className="signup-form d-flex justify-content-center">
        <form className="align-self-center col-12 col-md-8 col-lg-5 py-4 px-4">
            <h1 className="headerH1">Оплата участия в Ярмарке IZONE</h1>
            <hr/>
            <p style={{'font-size':'1.5em', 'margin-top':'2em'}}><center>Спасибо за оплату, наш менеджер свяжется с вами после получения информации от банка.</center></p>
        </form>
    </div>
    )
}