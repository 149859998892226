import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

const config = {
    apiKey: "AIzaSyC6ZJyIn5jaT5elB37JoyfDSSB1QQjgh9w",
    authDomain: "izonick-b19b9.firebaseapp.com",
    databaseURL: "https://izonick-b19b9.firebaseio.com",
    projectId: "izonick-b19b9",
    storageBucket: "izonick-b19b9.appspot.com",
    messagingSenderId: "16769100975"
};
firebase.initializeApp(config);

export const provider = new firebase.auth.FacebookAuthProvider();
export const auth = firebase.auth();
export default firebase;