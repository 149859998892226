import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { Provider } from 'react-redux';
import { auth } from '../../firebase_base';
import store from './store';
import * as ArtistActions from './actions';
import ParticipantList from './ParticipantList';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      userLoaded: false,
      user: null,
      unsubscribe: null,
    };
    this.logout = this.logout.bind(this);
    this.loadArchives = this.loadArchives.bind(this);
    this.changeArchive = this.changeArchive.bind(this);

  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      window.localStorage.setItem('user', JSON.stringify(user));
      let unsubscribe = null;

      if (user) {
        unsubscribe = store.subscribe(this.loadArchives);
      }

      this.setState({ userLoaded: true, user, unsubscribe });
    });
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  archiveList() {
    const result = window.prompt('Archive name');
    if (result) {
      store.dispatch(ArtistActions.archiveArtists(result));
    }
  }

  changeArchive() {
    if (this.archiveSelect.value !== 'root') {
      store.dispatch(ArtistActions.changePath(`archive/${this.archiveSelect.value}/artists`));
    } else {
      store.dispatch(ArtistActions.changePath('artists'));
    }
  }

  loadArchives() {
    this.setState({ ...this.state, archives: store.getState().archives });
  }


  logout() {
    auth.signOut()
      .then(() => {
        window.localStorage.setItem('user', '');
        this.setState({
          user: null,
        });
      });
  }


  render() {

    if (this.state.userLoaded && this.state.user === null) {
      return <Redirect to="/login"/>
    }

    return (
      <Provider store={store}>
        <div>
            <div className="container-fluid Backend">
              <div className="row auth">
                <div className="col-9">IZONICK</div>
                <div className="col-3 listSelector">
                  <button className="float-right" onClick={this.logout}>Logout</button>
                </div>
              </div>
              { this.state.userLoaded && (<div><ParticipantList /></div>) }
            </div> 
        </div>
      </Provider>
    );
  }
}

export default App;

