import React from 'react';
import "./Popup.css";

export default (props) => {
    return (
        <div className="Popup__Fade">
                <div className="Popup">
                    {props.content}
                </div>
        </div>
    )
}