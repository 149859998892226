import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import FrontEnd from './components/FrontEnd';
import BackEnd from './components/BackEnd';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Payment from './components/Payment';
import PaymentComplete from './components/PaymentComplete';

const Routes = props => (
  <Router>
    <Switch>
      <Route exact name="index" path="/" component={FrontEnd} />
      <Route exact name="login" path="/login" component={Login} />
      <Route exact name="back" path="/back" component={BackEnd} />
      <Route exact name="payment" path="/payment/:id" component={Payment} />
      <Route exact name="payment_complete" path="/payment_complete/" component={PaymentComplete} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
