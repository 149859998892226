import React, { Component } from 'react';
import './WarningBox.css';

class WarningBox extends Component {
    render() {
        return (this.props.visible) ? (
            <div className="WarningBox">
                <h4>{this.props.title}</h4>
                <p>{this.props.description}</p>
            </div>
        ) : (<div></div>)
    }
}

export default WarningBox;