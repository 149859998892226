import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import firebase from '../../firebase_base';
import { listenToFirebase } from './actions';


const initialState = {
  artists: [],
  activeArtist: null,
  archives: [],
  activeRoot: 'izolyatsia-jobs',
};

const ArtistReducer = function artistReducer(state = initialState, action) {
  const artistsRef = firebase.database().ref(state.activeRoot);

  if (action.type === 'ADD_COMMENT') {
    const { comment } = action;
    const comments = artistsRef.child(comment.artist_id).child('_comments');
    comment.created_date = Date.now();
    comments.push(comment);
    return { ...state };
  }

  if (action.type === 'EDIT_COMMENT') {
    const { comment } = action;
    const dbComment = artistsRef.child(comment.artist_id).child('_comments').child(comment.id);
    comment.edited_date = Date.now();
    dbComment.update(comment);
    return { ...state };
  }

  if (action.type === 'DELETE_COMMENT') {
    const { comment } = action;
    const dbComment = artistsRef.child(comment.artist_id).child('_comments').child(comment.id);
    dbComment.remove();
    return { ...state };
  }

  if (action.type === 'SET_ACTIVE_ARTIST') {
    const { artistId } = action;
    return { ...state, activeArtist: artistId };
  }

  if (action.type === 'UPDATE_ARTISTS') {
    let { activeArtist } = state;
    if (activeArtist == null) {
      activeArtist = 0;
    }
    return { ...state, activeArtist, artists: action.data };
  }


  if (action.type === 'CHANGE_PATH') {
    const { path } = action;
    listenToFirebase(path);
    return { ...state, activeRoot: path, activeArtist: null };
  }

  if (action.type === 'UPDATE_ARCHIVES') {
    return { ...state, archives: action.archives };
  }

  if (action.type === 'ARCHIVE_ARTISTS') {
    const archiveRef = firebase.database().ref('archive');
    artistsRef.once('value', (snap) => {
      archiveRef.push({
        date: Date.now(),
        title: action.name,
        artists: snap.val(),
      });
    });

    return { ...state };
  }
  
  if (action.type === 'SET_ARTIST_VALUE') {
    const { field } = action;
    const { artistId } = action;
    const { status } = action;

    
    state.artists = state.artists.map( e => {
        if (e.id === artistId) {
          e[field] = status;
        }
        return e;
    });
    
    const artist = artistsRef.child(artistId);
    artist.update({ [field]: status });
    return { ...state };
  }


  return state;
};

const store = createStore(ArtistReducer, applyMiddleware(thunk));

listenToFirebase();

export default store;
