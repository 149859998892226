import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { auth } from '../../firebase_base';
import './Login.css';
import Spinner from '../Spinner';

class Login extends Component {

    constructor() {
        super();
        this.state = {
            loggedIn: false,
            logging: false, 
            error: null,
        }

        this.login = this.login.bind(this);
    }

    login(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({'error': null, 'logging': true});
        auth.signInWithEmailAndPassword(this.email.value, this.password.value).then(e => {
            this.setState({'loggedIn': true});
        }).catch(e=> {
            this.setState({'error': e.message, 'logging': false});
            console.log(e);
        })
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect to="/back" />;
        }

        const form = (<form>
            { this.state.error && (<p className="LoginForm__Error">{this.state.error}</p>) }
            <p className="LoginForm__Field">
                <label htmlFor="LoginEmail" className="LoginForm__Label">Login:</label>
                <input autoComplete="email" ref={(c) => this.email = c} id="LoginEmail" className="LoginForm__Input" type="email"/>
            </p>

            <p className="LoginForm__Field">
                <label htmlFor="LoginPassword" className="LoginForm__Label">Password:</label>
                <input autoComplete="current-password" ref={(c) => this.password = c} id="LoginPassword" className="LoginForm__Input" type="password"/>
            </p>

            <footer className="LoginForm__Footer">
                <button onClick={this.login} className="btn">Login</button> 
            </footer>
        </form>);

        const spinner = (<Spinner/>);

        return (
            <div className="LoginForm d-flex justify-content-center">
                <div className="LoginForm__Inside align-self-center col-12 col-md-8 col-lg-4 py-4 px-4">
                    <h1 className="LoginForm__Title">IZONICK</h1>
                    { this.state.logging ? spinner: form }
                </div>
            </div>
        );
    }

}

export default Login;


