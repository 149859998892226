import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ArtistActions from '../BackEnd/actions';
import {getSchemaColor} from '../../utils';
import './StatusSelector.css';


class StatusSelector extends Component {

    constructor() {
        super();
        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(e) {
        e.preventDefault();
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(e);
        }

        if (this.props.record_id === undefined) {
            return;
        }

        const status = this.props.options.find(option => option.name === e.target.value);

        if (status.delete === true && !window.confirm("Действительно удалить?")) {
            let status = this.props.item === undefined?"None":this.props.item;
            e.target.value = status;
            return false;
        }
        this.props.actions.setArtistValue(this.props.type, this.props.record_id, status.name);
    }
      

    getLabel(status) {
        if ('shortcut' in status) {
            return status.shortcut;
        }
        return status.label;
    }

    getColor() {
        return getSchemaColor(this.props.options, this.props.item, this.props.type==='owner'?1:0);
    }

    render() {
        
       const options = this.props.options.map( status => {
                let options = [];
                if (status.delete === true) {
                    options.push(<option key="placeholder" disabled="disabled">—</option>);
                }
                options.push(<option value={status.name} key={status.name}>{this.getLabel(status)}</option>);
                return options;
            } 
        )

       return (
           <select 
                value={this.props.item} 
                onChange={this.changeStatus} 
                className={['StatusSelector', (this.props.item === 'None' || this.getColor() === false || this.props.item === undefined)?'StatusSelector__None':'StatusSelector__'+this.props.item].join(' ')}
                style={{'--statusColor':this.getColor()}}
            >
            {options}
           </select>
        );
    }

}
 
function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ArtistActions, dispatch),
    };
}
  
  export default connect(
    null,
    mapDispatchToProps,
  )(StatusSelector);


