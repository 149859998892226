import firebase from '../../firebase_base';
import Artist from '../../schema';
import store from './store';

let globalRef = null;

export function sendComment(comment) {
  return {
    type: 'ADD_COMMENT',
    comment,
  };
}

export function editComment(comment) {
  return {
    type: 'EDIT_COMMENT',
    comment,
  };
}

export function deleteComment(comment) {
  return {
    type: 'DELETE_COMMENT',
    comment,
  };
}

export function archiveArtists(name) {
  return {
    type: 'ARCHIVE_ARTISTS',
    name,
  };
}


export function changePath(path) {
  return {
    type: 'CHANGE_PATH',
    path,
  };
}

export function setActiveArtist(artistId) {
  return {
    type: 'SET_ACTIVE_ARTIST',
    artistId,
  };
}

export function setArtistValue(field, artistId, status) {
  return {
    type: 'SET_ARTIST_VALUE',
    field,
    artistId,
    status,
  };
}

export function listenToFirebase(path = 'izolyatsia-jobs') {
  if (globalRef) {
    globalRef.off();
  }

  const archiveRef = firebase.database().ref('archive');

  archiveRef.once('value', (snapshot) => {
    const archives = Object.entries(snapshot.val()).map(e => ({ id: e[0], title: e[1].title }));
    setTimeout(() => {
      store.dispatch({ type: 'UPDATE_ARCHIVES', archives });
    });
  });

  globalRef = firebase.database().ref(path);
  globalRef.on('value', (snapshot) => {
    const items = snapshot.val();
    const data = [];

    if (snapshot.val() === null) {
      return;
    }

    Object.entries(items).forEach((val, index) => {
      const item = val[1];

      let singleArtist = { id: val[0] };
      Artist.forEach((schema) => {
        singleArtist[schema.name] = item[schema.name];
      });

      singleArtist = { id: val[0], ...item };

      let comments = [];
      for (const i in item._comments) {
        comments.push(Object.assign(item._comments[i], { id: i }));
      }

      comments = comments.sort((a, b) => a.created_date < b.created_date);
      singleArtist._comments = comments;
      singleArtist.index = index + 1;
      data.push(singleArtist);
    });

    setTimeout(() => {
      store.dispatch({ type: 'UPDATE_ARTISTS', data });
    });
  });
}