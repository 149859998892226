import React, { Component } from 'react';
import './FrontEnd.css';
import firebase from '../firebase_base';
import WarningBox from './WarningBox/WarningBox';
import Artist from '../schema';
import PositionDescription from './PositionDescription';

class FrontEnd extends Component {
  constructor() {
    super();

    const artistSchema = {};
    Artist.forEach((element) => {
      if (element.default) {
        artistSchema[element.name] = element.default;
      } else {
        artistSchema[element.name] = '';
      }
    });
    artistSchema.formSubmitted = false;

    this.state = artistSchema;

    this.state.files = [];
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFilesDrag = this.uploadFilesDrag.bind(this);
    this.uploadFilesDragLeave = this.uploadFilesDragLeave.bind(this);
    this.uploadFilesDrop = this.uploadFilesDrop.bind(this);
    this.uploadDialog = this.uploadDialog.bind(this);
    this.handleFileDialogueChange = this.handleFileDialogueChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const artistsRef = firebase.database().ref('izolyatsia-jobs');

    const artist = {};
    Artist.forEach((element) => {
      artist[element.name] = this.state[element.name];
    });

    artist.registration_time = Date.now();
    artist.files = this.state.files;

    artistsRef.push(artist);

    const artistSchema = {};
    Artist.forEach((element) => {
      artistSchema[element.name] = '';
    });
    artistSchema.formSubmitted = true;

    this.setState(artistSchema);
  }

  handleFileDialogueChange(e) {
    this.uploadMultipleFile(e.target.files);
  }

  uploadDialog(e) {
    e.preventDefault();
    e.stopPropagation();
    document.querySelector('.uploadFiles__Original').click();
  }

  uploadFilesDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    const zone = document.querySelector('.uploadFiles');
    zone.classList.add('uploadFiles--drag');
  }

  uploadFilesDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    const zone = document.querySelector('.uploadFiles');
    zone.classList.remove('uploadFiles--drag');
  }

  uploadMultipleFile(files, fieldName) {
    this.setState({'loading':true});
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return
      const name = files[i].name;
      const reader = new FileReader();
      const uniqueId = name + '_' + Math.random().toString(36).substring(2) + Date.now().toString(36);

      this.setState({files: [{'id':uniqueId, 'field':fieldName, 'name':name, 'file':''}, ...this.state.files]});
      
      reader.onload = event => {

        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("POST", 'https://warehouse.izolyatsia.org/pushfile.php', true);
        var formData = new FormData();
        formData.append("file", event.target.result);
        formData.append("name", name);
        xmlHttpRequest.onload = e => {
          let data = JSON.parse(e.target.response);

          if (data.status === 'ok') {
            const updatedFiles = [];
            this.state.files.forEach(e => {
              if (e.id === uniqueId) {
                e.file = data.file;
              }
              updatedFiles.push(e);
            })
            this.setState({files: updatedFiles});
            console.log(this.state.files);
          }
          
        }
        xmlHttpRequest.send(formData);
      };
      reader.readAsDataURL(files[i]);       
    }
    this.setState({'loading':false});
  }


  uploadFilesDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    const zone = document.querySelector('.uploadFiles');
    zone.classList.remove('uploadFiles--drag');

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let files = e.dataTransfer.files;        
      this.uploadMultipleFile(files);
      
    }
  }

  selectChange(event, name) {
    this.setState({[name]: event.target.value});
  }

  fileChange(event, name) {
    this.uploadMultipleFile(event.target.files, name);
    this.setState({[name]: event.target.value});
  }

  render() {

    const fileList = 
      this.state.files.map((file) => { const text = file.name; const liClass = file.file === ''?'fileList__loading':''; return (<li className={liClass} key={Math.random()}>{text}</li>)});

    let form =
      (<div>
        <fieldset>
          {Artist.map((element) => {
                      if (element.type === 'textarea') {
                          return (<div className='mt-4' key={element.name}>
                            <label className='w-100'><span className='form-label d-block'>{element.label}</span>
                            <textarea key={element.name} name={element.name} type={element.type} onChange={this.handleChange} value={this.state[element.name]} className='form-control w-100' rows="3"/>
                            </label>
                          </div>); 
                      }

                      if (element.type === 'select') {
                        return (
                          <div key={element.name} className="uploadFile my-4">
                            <label className='w-100'><span className='form-label d-block'>{element.label}</span>
                            <select className='form-select' key={element.name} name={element.name} value={this.state[element.name]?this.state[element.name]:element.values[0]} onChange={e=>this.selectChange(e,element.name)}>
                              {element.values.map(e=>(<option key={e} value={e}>{e}</option>))}
                            </select>
                            </label>
                            <PositionDescription position={this.state.position}/>
                          </div>
                        ); 
                      }  

                      if (element.type === 'file') {
                        return (
                          <div key={element.name} className="uploadFile my-4">
                            <label className='w-100'><span className='form-label d-block'>{element.label}</span>
                            <input className="uploadFile form-control" key={element.name} name={element.name} type="file" value={this.state[element.name]} multiple="multiple" required={element.required} onChange={e=>this.fileChange(e,element.name)}/>
                            </label>
                          </div>
                        ); 
                      }  

                      if (element.type === 'files') {
                        return (
                          <div key={element.name} className="uploadFiles my-5" onDragEnter={this.uploadFilesDrag} onDragOver={this.uploadFilesDrag} onDragLeave={this.uploadFilesDragLeave} onDrop={this.uploadFilesDrop}>
                            <p className="uploadFiles__title form-control"><strong>Перетащите {element.placeholder}</strong> </p>
                       
                            <p><button onClick={this.uploadDialog} className="uploadFiles__btn">Выбрать файлы</button></p>

                            <ul>
                                {fileList}
                            </ul>

                            <input className="uploadFiles__Original form-control" key={element.name} name={element.name} type="file" placeholder={element.placeholder} onChange={this.handleFileDialogueChange} value={this.state[element.name]}  multiple="multiple" />
                          </div>
                        ); 
                      }                         
                        return (<div className='my-4' key={element.name}>
                          <label className='w-100'><span className='form-label d-block'>{element.label}</span>
                          <input required={element.required} name={element.name} type={element.type} onChange={this.handleChange} value={this.state[element.name]} className='form-control' />
                          </label>
                        </div>);
                  })}
        </fieldset>
      </div>);

    if (this.state.formSubmitted) {
      form = (<div></div>);
    }
    return (
      <div className="d-flex justify-content-center">
        <div className="card col-12 col-md-8 col-lg-5 my-lg-4 shadow-lg">
        <img src="/static/img/jobs_web.svg" className="card-img-top mw-100" alt="IZOLYATSIA looks for new project team" />
        <form
          className="w-100 px-2"
          onSubmit={this.handleSubmit}
        >

          <div className="card-body">
          { form }
          <WarningBox
            visible={this.state.formSubmitted}
            title="Thank you for your submission!"
            description="
            Please note that due to the expected volume of applications, only candidates selected for interview will be contacted. Thank you for your understanding."
          />
          </div>
          <div className="card-body d-flex justify-content-between">
            <span className='w-100 d-flex'>
            {(!this.state.formSubmitted?<button disabled={this.state.loading} className='mx-1 btn btn-primary'>Submit</button>:"")}
            </span>
            <span><a className="pull-right" href="https://izolyatsia.org">IZOLYATSIA</a></span>
          </div>
        </form>
        </div>
      </div>
    );
  }
}

export default FrontEnd;
