import React, { Component } from 'react';
import './ArtistCard.css';
import Artist from '../../schema.js';
import Autolinker from 'autolinker';
class ArtistCard extends Component {
    render() {
        if (this.props.artist) {
            return (
                <div className="ArtistCard">
                    {Artist.map((element) => {
                            if (element.name==='links') {
                                if (!this.props.artist['files'] || this.props.artist['files'].length===0) {
                                    return ' ';
                                }
                                const files =  this.props.artist['files'].map(e=> <span className="ArtistCard__File" key={e.id}><a className="ArtistCard__File__Link" target="_blank" href={e.file} rel="noopener noreferrer">{e.name}</a></span>)
                                return <p className="ArtistCard__Line" key={element.name}>{files}</p>;
                            } 
                                
                            if (element.type==='file') {
                                if (!this.props.artist['files'] || this.props.artist['files'].length===0) {
                                    return ' ';
                                }
                                console.log(this.props.artist['files'], element.name);
                                const files =  this.props.artist['files'].filter(e=>e.field===element.name).map(e=> <span className="ArtistCard__File" key={e.id}><a className="ArtistCard__File__Link" target="_blank" href={e.file} rel="noopener noreferrer">{e.name}</a></span>)
                                return <p className="ArtistCard__Line" key={element.name}>
                                        <label className="ArtistCard__Label">{element.label}:</label> 
                                        {files}
                                        </p>;
                            } 
                            console.log(this.props.artist);
                            return <p className="ArtistCard__Line" key={element.name} title={this.props.artist[element.name]}>
                                <label className="ArtistCard__Label">{element.label}:</label> 
                                <span className="ArtistCard__Text" dangerouslySetInnerHTML={ { __html:Autolinker.link(this.props.artist[element.name])} }/></p>;

                        } 
                    )}
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}

export default ArtistCard;