import React, { Component } from 'react';
import moment from 'moment';

class Comment extends Component {

    constructor(props) {
        super(props);
        
        this.currentUser = JSON.parse(localStorage.getItem("user"));

        this.state = {
            comment: props.comment.comment,
            user: {
                email: this.currentUser.displayName || this.currentUser.email,
            },
            editing: props.editing,
            comment_id: props.comment.id
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleEdit(e) {
        e.preventDefault();

        this.setState({
            editing: !this.state.editing
        });
    }

    handleDelete(e) {
        e.preventDefault();

        if (window.confirm("Хорошо подумал? 🤔")) {
            var comment = {
                artist_id: this.props.artist_id,
                user: this.state.user,
                comment: this.state.comment
            }
            this.props.deleteComment({...comment, id:this.state.comment_id});
        }
    }

    handleSubmit(e) {
        e.preventDefault();


        if (this.state.editing) {
            var comment = {
                artist_id: this.props.artist_id,
                user: this.state.user,
                comment: this.state.comment
            };
            if (this.state.comment_id) {
                this.props.editComment({...comment, id:this.state.comment_id});
                this.setState({
                    editing: false
                });
            } else {
                this.props.sendComment(comment);
                this.setState({
                    comment: ''
                });
            }
        } else {
            this.setState({
                editing: false
            })
        }
    }

    componentDidMount() {
        this.box.style.display = 'block';
        this.box.getBoundingClientRect();
        this.box.style.opacity = 1;
    }

    render() {

        let save_button = <span></span>;
        let comment_area = <p></p>;
        let comment = this.props.comment;
        let edit_button = <span></span>;
        let delete_button = <span></span>;
        let user_name = comment.user?comment.user.email:'';


        if (this.state.editing) {
            save_button = <button className="btn">Сохранить</button>
            comment_area = <textarea className="Comment__TextArea" ref={e => {this.textArea = e}} name="comment" onChange={this.handleChange} value={this.state.comment}></textarea>
            if (this.state.comment_id) {
                delete_button = <button className="deleteButton" onClick={this.handleDelete}>Удалить</button>
            }
        } else {
            if (user_name === this.state.user.user_name) {
                edit_button = <button className="editButton" onClick={this.handleEdit}>✎</button>
            }
            if (comment) {
                comment_area = <p>{comment.comment}</p>
            }
        }

        let edited_star = <span></span>;
        if (this.props.comment.edited_date) {
            edited_star = <span title={this.state.edited_date}>*</span>
        }

        return (
            <div className="singleComment" ref={(e)=>{this.box=e}}>
                <form onSubmit={this.handleSubmit}>
                    { comment && (
                        <header>
                            <h5>{edit_button} {user_name.split('@')[0]}</h5>
                            <small>{edited_star}{moment(comment.created_date).format('DD.MM.YYYY, HH:mm')}</small>
                        </header>
                    )
                    }
                    <fieldset>
                        {comment_area}
                    </fieldset>
                    {save_button}
                    {delete_button}
                </form>
            </div>
        );

        
    }
}

export default Comment;