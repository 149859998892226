import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ArtistActions from './actions';
import ArtistCard from '../ArtistCard/ArtistCard';
import Spinner from '../Spinner';
import Popup from '../Popup';
import CommentList from '../Comments/CommentList';
import StatusSelector from '../StatusSelector';
import {getSchemaColor} from '../../utils';
import Artist, {Statuses, TableStructure} from '../../schema';
import uuid from 'uuid/v3';

import moment from 'moment';

class ParticipantList extends Component {
  constructor() {
    super();
    const data = [];
    this.state = { artists: data, paymentUrl: false, filter_search:false, filter_status:false };
    this.artistClick = this.artistClick.bind(this);
    this.getPaymentURL = this.getPaymentURL.bind(this);
    this.closePaymentURLPopup = this.closePaymentURLPopup.bind(this);
    this.countWorkingRecords = this.countWorkingRecords.bind(this);
    this.countPayedRecords = this.countPayedRecords.bind(this);
    this.countReserved = this.countReserved.bind(this);
    this.countNotPayedRecords = this.countNotPayedRecords.bind(this);
    this.getFilteredRecords = this.getFilteredRecords.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.getActiveArtist = this.getActiveArtist.bind(this);
  }

  setStatusFilter(e) {
    e.preventDefault();
    this.props.actions.setActiveArtist(null);
    if (e.target.value === 'No_Filter') {
      this.setState({'filter_status': false});
    } else {
      this.setState({'filter_status': e.target.value});
    }
    
  }

  changeSearch(e) {
    e.preventDefault();
    this.props.actions.setActiveArtist(null);
    this.setState({'filter_search': e.target.value});
  }

  getActiveArtist() {
    return this.getFilteredRecords().find(e=>e.id === this.props.activeArtist) || null;
  }

  getFilteredRecords() {

    if (this.state.filter_search !== false && this.state.filter_search.length > 0) {
      console.log(this.props.artists[0].name.toLowerCase().indexOf(this.state.filter_search.toLowerCase()));
      return this.props.artists.filter(e => e.name.toLowerCase().indexOf(this.state.filter_search.toLowerCase()) !== -1);
    }

    if (this.state.filter_status !== false) {
        return this.props.artists.filter(e => e.position === this.state.filter_status);
    } 
    return this.props.artists.filter(e => e._status !== 'Hidden');
  }

  countWorkingRecords() {
    return this.getFilteredRecords().filter(e => 
        e._status !== undefined 
          && e._status !== 'None' 
          && e._status !== 'Ban' 
          && e._status !== 'Reserved',
        ).length
  }

  countReserved() {
    return this.getFilteredRecords().filter(e => 
          e._status === 'Reserved',
        ).length
  }

  countPayedRecords() {
    return this.getFilteredRecords().filter(e => e._status === "AnswerAndMoney").length
  }

  countNotPayedRecords() {
    return this.getFilteredRecords().filter(e => e._status === "AnswerNoMoney" || e._status === "Waiting").length;
  }

  updateActiveArtist(id) {
    const artist = this.getFilteredRecords().find(element => element.id === id);
    this.setState({ activeArtist: artist });
  }

  artistClick(e, activeArtistId) {
    e.preventDefault();
    this.props.actions.setActiveArtist(activeArtistId);
  }

  getPaymentURL(e) {
    e.preventDefault();
    if (this.getActiveArtist().payment_key === undefined) {
      this.props.actions.setArtistValue('payment_key', this.getActiveArtist().id, uuid(this.getActiveArtist().id, uuid.URL));
    }

    const payment_key = this.getActiveArtist().payment_key;
    
    this.setState({
        'paymentUrl': 'https://fair.izone.ua/payment/' + payment_key
      }
    );

  }

  closePaymentURLPopup() {
    this.setState({'paymentUrl':''});
  }

  createCell(cell, item) {

    let Components = {'StatusSelector': StatusSelector};

    if ('component' in cell && cell.component !== '') {
      return React.createElement(Components[cell.component], {...cell.props, record_id: item.id, item: item[cell.props.type]});
    }

    if (cell.expr === 'date') {
      return moment(item.registration_time).format('DD.MM.YYYY');
    }

    if (cell.expr === 'index') {
      return item.index;
    }

    if (cell.expr === 'name') {
      return item.name;
    }
  
    if (cell.expr === 'position') {
      return item.position;
    }

    if (cell.expr === 'comments_count') {
      return item._comments.length;
    }
  }

  render() {

    const paymentForm = (
      <form>
        <p><label><strong>Ссылка на оплату:</strong> <br/>
        <input type="text" className="PopupInput" value={this.state.paymentUrl} />
        </label></p>
        <button onClick={this.closePaymentURLPopup} className="btn">Закрыть</button>
      </form>
    );

    const tableHeader = 
    (<li className="header">
        {TableStructure.map((cell, indx) => 
        (<span key={indx} className={cell.className}>{cell.label}</span>)
         )}
    </li>);

    const tableBody = this.getFilteredRecords().map((item, index) => (
      <li
      className={
          [
              'Participant_Status', 
              (item._status==='None' || item._status === undefined)?'Participant_Status__None':'', this.getActiveArtist() && item.id === this.getActiveArtist().id ? 'active' : ''
          ].join(' ')
      }
      onClick={e => this.artistClick(e, item.id)}
      style={{'--statusColor':getSchemaColor(Statuses, item._status)}}
      key={item.id}
      rel={item.id}
    >
        {
          TableStructure.map((cell, indx) => 
            (
              <span key={indx} className={cell.className}>{this.createCell(cell, item)}</span>
            )
          )
        }
    </li>
    ));

    return (
      <div className="participants-list">
        { this.state.paymentUrl && (
            <Popup content = {paymentForm}/>
        )}

        <div className="row searchForm">
            <div className="col-12 col-md-9 list list--filter">
                <input type="text" placeholder="Поиск..." onChange={e=>this.changeSearch(e)} className="PopupInput" value={this.filter_search}/>
                <select className='form-select w-25' onChange={e=>this.setStatusFilter(e)}>
                  <option value="No_Filter" key="No_Filter">All records</option>
                  {Artist[0].values.map(e=>(<option value={e} key={e}>{e}</option>))}
                </select>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-9 list">
            <ul>
              {tableHeader}
              {this.getFilteredRecords().length === 0 && <Spinner /> }
              {this.getFilteredRecords() && tableBody}

              <li className="header">
                <div className="total">
                  <span className="totalSpan">Всего: {this.getFilteredRecords().length}</span>
                  <span className="totalSpan">В работе: {this.countWorkingRecords()}</span>
                  <span className="totalSpan">Резерв: {this.countReserved()}</span>
                </div>             
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3 sidebar">

            {this.getActiveArtist() != null &&
                  (
                  <ArtistCard artist={this.getActiveArtist()} />
                  )
            }

            {this.getActiveArtist() != null &&
              (
              <CommentList
                sendComment={this.props.actions.sendComment}
                editComment={this.props.actions.editComment}
                deleteComment={this.props.actions.deleteComment}
                artist_id={this.getActiveArtist().id}
                comments={this.getActiveArtist()._comments}
              />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    artists: state.artists,
    activeArtist: state.activeArtist,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ArtistActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParticipantList);
