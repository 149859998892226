import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import store from '../BackEnd/store';
import Spinner from '../Spinner';

import "./Payment.css";

//var LiqPay = require('sdk-nodejs/lib/liqpay');

const priceList = [
    {
        label: 'Участие в ярмарке (300 грн)',
        name: 300,
    },
    {
        label: 'Участие в ярмарке без присутствия участника (500 грн)',
        name: 500,
    }
]

class Payment extends Component {

    constructor () {
        super();
        this.state = {
            amount: 300,
        }
        this.getForm = this.getForm.bind(this);
        this.getRecord = this.getRecord.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(e) {
        e.preventDefault();
        this.setState({amount: e.target.value});
    }

    getRecord() {
        const artist =  this.props.artists.find( e => e.payment_key === this.props.id);
        return artist;
    }

    getForm() {
        /*var liqpay = new LiqPay('i41267697772', 'E6kSG139HgLEACUHyrca8AZp31BOBgl6OpUVny7u');
        const record = this.getRecord();
        var html = liqpay.cnb_form({
        'action'         : 'pay',
        'amount'         : this.state.amount,
        'currency'       : 'UAH',
        'description'    : `Оплата за участь в BOZARt від ${record===undefined?'':record.name}`,
        'order_id'       : this.props.id,
        'version'        : '3',
        'sandbox'        : 0,
        'language'       : 'en',
        'server_url'     : 'https://us-central1-izonick-b19b9.cloudfunctions.net/liqPayBackend',
        'result_url'     : 'https://fair.izone.ua/payment_complete/'
        });
        return html;*/
    }

    render() {
        const record = this.getRecord();
        if (!record) {
            return (<Spinner/>);
        } else {
            const options = priceList.map( status => {
                    let options = [];
                    options.push(<option value={status.name} key={status.name}>{status.label}</option>);
                    return options;
                } 
            )

            const priceSelector = (
                <select 
                value={this.state.amount} 
                onChange={this.changeStatus} 
                className="priceSelector"
            >
            {options}
           </select>
            );

            return (
                <div className="signup-form d-flex justify-content-center">
                    <div className="signup-form-inside align-self-center col-12 col-md-8 col-lg-5 py-4 px-4">
                        <h1 className="headerH1">Оплата участия в Ярмарке IZONE</h1>
                        <hr/>
                        <p><strong>Участник:</strong> {record.name} <br/>
                          {priceSelector}
                        </p>
                        <div dangerouslySetInnerHTML={{__html:this.getForm()}}>
                        </div>
                    </div>
                </div>
            )
        }
    }

}


function mapStateToProps(state) {
    return {
      artists: state.artists,
    };
  }
  
  const PaymentStore = connect(
    mapStateToProps,
    null
  )(Payment);


  class PaymentWrapper extends Component {
    render() {        
            return (

                
                <Provider store={store}>
                    <PaymentStore id={this.props.match.params.id}/>
                </Provider>
            )
    }
}
  
export default PaymentWrapper;