import React, { Component } from 'react';
import Comment from "../Comments/Comment.js";

class CommentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            artist_id: props.artist_id,
            comments: props.comments
        }
    }

    render() {
        return (
            <div className="commentBox">
                <div className="commentBoxForm">
                    <Comment sendComment={this.props.sendComment} key={this.state.artist_id} artist_id={this.props.artist_id} editing="true" comment=''/>
                </div>
                <div className="commentBoxList">
                    {this.props.comments && this.props.comments.map(com => {
                        return (<Comment editComment={this.props.editComment} deleteComment={this.props.deleteComment} key={com.id} artist_id={this.props.artist_id} editing={false} comment={com} />)
                    })}
                </div>
            </div>
        )
    }
}

export default CommentList;