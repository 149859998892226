const Artist = [
  {
    name: 'position',
    placeholder: 'Position',
    label: 'Position',
    type: 'select',
    default: 'Project Leader',
    values: ['Project Leader', 'Project Coordinator', 'Lead Financial Manager', 'Cultural Programme Coordinator', 'Accountant', 'Communication and SMM manager', 'Sub-grant manager', 'Gurtobus Leader', 'Gurtobus manager', 'Gurtobus logistics coordinator'],
    required: true,
  },
  {
    name: 'name',
    placeholder: 'Your name',
    label: 'Full name',
    type: 'text',
    required: true,
  },
  {
    name: 'email',
    placeholder: 'Your Email',
    label: 'E-mail',
    type: 'email',
    required: true,
  },
  {
    name: 'cv',
    placeholder: 'CV',
    label: 'CV with salary expectations',
    type: 'file',
    required: true,
  },
  {
    name: 'personal_statement',
    placeholder: 'Personal statement',
    label: 'Personal statement (500 words max.)',
    type: 'file',
    required: true,
  },
  {
    name: 'comment',
    placeholder: 'Comments',
    label: 'Comment',
    type: 'textarea',
    required: false,
  },
];

export default Artist;


const Statuses = [
  {
    name: 'None',
    label: 'Необработано',
    color: '#777',
  },
  {
    name: 'Ban',
    label: 'Забанен',
    color: '#000',
  },
  {
    name: 'Selected',
    label: 'Выбран',
    color: '#027368'
  },
  {
    name: 'Waiting',
    label: 'Ждём ответа',
    color: '#5377A6'
  },
  {
    name: 'AnswerNoMoney',
    label: 'Запрос оплаты выше бюджета',
    color: '#040BDB'
  },
  {
    name: 'NoMotivation',
    label: 'Недостаточная мотивация',
    color: '#040BDB'
  },
  {
    name: 'NoExperience',
    label: 'Недостаточный опыт',
    color: '#040BDB'
  },
  {
    name: 'Intreview',
    label: 'Пригласить на интервью',
    color: '#80BF21'
  },
  {
    name: 'Trouble',
    label: 'Есть проблема',
    color: '#F56A98'
  },
  {
    name: 'Reserved',
    label: 'Резерв',
    color: '#E8B068'
  },
  {
    name: 'Hidden',
    label: 'Спрятать',
    color: '#CCC',
    delete: true
  },
];


const Users = [
  {    
  name: "None",
  label: "Нет менеджера",
  shortcut: "",
  },

  {
    name: "anna.kishinska@izolyatsia.org",
    label: "Аня",
    color: '#04BFBF',
    shortcut: 'А',
  },
  {
    label: "Андрей",
    name: "andrej.gorokhov@izolyatsia.org",
    color: '#F20530',
    shortcut: 'Г',
  },
  {
    label: "Ваня",
    name: "ivan.pyvovarov@izone.ua",
    color: '#034C8C',
    shortcut: 'В',
  },
]

const TableState = [
  {
    name: 'clean',
    label: 'чистый стол', 
  },
  {
    name: 'clean+net',
    label: 'стол+сетка', 
  },
  {
    name: 'clean net',
    label: 'чистая сетка', 
  },
  {
    name: 'wall',
    label: 'стена',
  },
  {
    name: 'table+wall',
    label: 'стол+стена',
  }
];

const Arts  = [
  {    
    name: 'None',
    label: 'Иное',
    shortcut: '📦',
  },
  {
    name: 'jewelry',
    label: 'Ювелирка',
    shortcut: '💎',
  },{
    name: 'dress',
    label: 'Тряпки',
    shortcut: '👗',
  },{
    name: 'sticker',
    label: 'Стикеры',
    shortcut: '📄',
  },{    
    name: 'paiting',
    label: 'Картины',
    shortcut: '🖼',
  },{    
    name: 'present',
    label: 'Подарки', 
    shortcut: '🎁',
  },
  {    
    name: 'graphics',
    label: 'Графика',
    shortcut: '🌼',
  },{  
    name: 'photo',
    label: 'Фото',
    shortcut: '📷',
  }
]

const TableStructure = [
  {
    className: 'select',
    label: '',
    expr: 'index'
  },
  {
    className: 'date',
    label: 'Date',
    expr: 'date'
  },
  {
    className: 'name',
    label: 'Name',
    expr: 'name',
  },
  {
    className: 'position',
    label: 'Position',
    expr: 'position'
  },
  {
    className: 'status',
    label: 'Status',
    component: 'StatusSelector',
    props: {    
      type: '_status',
      options: Statuses
    }
  },
  {
    className: 'comments_count',
    label: 'Comments',
    expr: 'comments_count'
  },
];

export { Statuses, Users, Arts, TableState, TableStructure };
