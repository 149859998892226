import React, { Component, Suspense } from 'react';
import "./PositionDescription.css";

class PositionDescription extends Component {

    render() {
        try {
            console.log(this.props.position.replace(' ','_'));
            const Component = React.lazy(() =>
                import(`./Pages/${this.props.position.replaceAll(' ','_')}.js`)
            );
            return <div className="PositionDescription border rounded p-2 my-2"><Suspense fallback={<div>Loading...</div>}><Component /></Suspense></div>;
        } catch (error) {
            return (<h1>{error}</h1>);
        }
        
    }
}

export default PositionDescription;


